.dividedpage {
    display: block;
    height: 100%;
    min-height: 100vh;

    @include md {
        display: grid;
        grid-template-columns: 200px 1fr;
    }

    .submenu{
        padding-top: $sm;
        background: rgba(170, 191, 216, 0.15);
        border-right: 1px solid $grey;

        @media screen and (max-width: 766px) {
            display: none;
        }

    }

    // .submenu hr{
    //     background: $grey;
    //     width: 100%;
    // }

    .fixedmenu{
        position: fixed;
        display: flex;
        flex-direction: column;
    }


    .title-nav-cat {
        @include caption-lg;
        margin-top: $sm !important;
    }

    .menu-item {
        @include body;
        height: 50px;
        border-bottom: 1px $grey solid;
        display: flex;
        align-items: center;
        width: 165px;

        &:hover {
            color: $link !important;
            font-weight: bold !important;
        }
    }



}
.menu-items {
    a {
        color: #00918e !important;
        font-weight: bold;
    }

}