// Spacing variables

$xs: 8px;
$sm: 16px;
$md: 24px;
$lg: 36px;

.margin-left-xs {
    margin-left: $xs !important;
}

.margin-left-sm {
    margin-left: $sm !important;
}

.margin-left-md {
    margin-left: $md !important;
}

.margin-right-sm {
    margin-right: $sm;
}

.margin-top-xs {
    margin-top: $xs;
}

.margin-top-md {
    margin-top: $md;
}

.margin-bottom-sm {
    margin-bottom: $sm;
}


