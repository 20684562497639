.navBar {
    background:white; 
    border-bottom: 1px solid $grey;
    box-shadow: 10px 10px 10px rgba(170, 191, 216, 0.25);
    position: fixed;
    z-index: 999;
    width: 100%;
    position: sticky;
    top: 0;
}

.top-nav-item {
    @include body;
}