.checkout {

    &__wrapper {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  
    &__heading {
      @include title-2;
    }
  
      &__form {
          @include product-container;
          padding: 1.5rem 2rem;
          margin: $md;
          width: 50%;
          @media screen and (max-width: 937px) {
            width: 100% !important;
            margin-left: $sm;
            margin-right: $sm;
        }
          
      }
  
      // &__subheading {
      //   @include caption-lg;
  
      //   &:not(:first-child) {
      //     padding-top: 1.25rem;
      //   }
      // }
  

      &__select {
        border: 1px solid $grey;
        border-radius: 7px;
        //padding: $sm;  
        width: 100%;
        height: 48px;
        font-size: 1.3rem;
        select > option {
          font-size: 1.3rem;
        }
      }
  
      &__btn-confirm {
         background-color: $primary;
         border: 1px solid $primary;
         padding-left: 1.25rem;
         padding: 0.5rem 0.75rem;
         font-size: 0.75rem;
         text-transform: uppercase;
         color: white;
         font-style: bold;
         margin-top: 1.5rem;
         display: block;
  
          &:hover {
              background-color: lighten(#292B83, 10);
          }
  
          &:disabled {
            cursor: not-allowed;
          }
      }
  
      &__summary {
        margin-top: 1.5rem;
        border: 1px solid $grey;
        border-radius: 7px;
        box-shadow: 15px 15px 15px rgba(170, 191, 216, 0.45);
        width: 30%;
        padding: $md;
        height: max-content;
  
        h4 {
          @include caption-lg;
          
        }
      }
  
      &__summary-details {
        // @apply flex my-3;
        display: flex;
        margin: 0.75rem;
        padding: 0rem 2rem;
      }
  
      // &__summary-img {
      //    width: 100px;
      //    height: 100px;
      //    object-fit: cover;
      // }
  
      &__summary-name {
        @include title-4; 
      }
  
      &__summary-value {
        margin-left: auto;
      }
  
      &__summary-price {
        @include caption-sm;
        justify-content: flex-end;
      }
  }

  .btnContainer {
    margin-left: 3rem;
  }
  .bttn {
    @include link-btn;
    margin-right: $xs;
  }

  .bttn-return {
    @include link-btn;
  }
  .btn-group {
    @include btn-group;
  }