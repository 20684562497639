.cart {

    // @media screen and (max-width: 935px) {
    //     width: 100% !important;
    //     margin-bottom: $sm;
    //   } 

    &__inner {
        padding: 1.25rem;
    }


    &__none {
        padding: 1.25rem;
        color: $primary;
        text-align: center;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        margin-top: $md;
        margin-bottom: $md;
        margin-right: $sm;
        margin-left: $sm;

    }

    &__btn-empty {
        @include secondary-btn;
        align-self: flex-start;
    }

    &__btn-checkout {
        @include primary-btn;
        // @media screen and (max-width: 935px) {
        //     width: 100%;
        //   } 
    }

    &__btn-secondary {
        @include secondary-btn;
    }

    .cart-item {
        display: flex;
        padding: 1.25rem;

        &__image {
            width: 4rem;
            height: 4rem;
            object-fit: cover;
            border: 2px solid $color-accent;
            margin-right: 0.75rem;
        }


        &__details-qty {
            display: flex;
            margin: 0 auto;
            font-size: 1rem;

            button {
                border: none;
                background: white;
                // padding: 0 6px;
                font-size: 1.25rem;
            }

            p {
                margin-bottom: 0;
                margin-top: 3px;
            }
        }

        &__remove {
            @include link-btn;
            margin-left: auto;
            align-self: flex-start;
            height: 25px;
            margin-bottom: $sm;
        }
    }
}


