@import "/typography";
@import "/mixins";


.input-container {
    flex: 1 1 auto;
    width: 200px;
}

.label {
    @include caption-sm;
    margin-bottom: $xs !important;
    margin-top: $md !important;

}

.input {
    @include title-4;
    @include border;
    border-radius: $border-radius;
    padding: $sm;
    height: 48px;
    display: flex;
    width: 100%;
  }

.error {
    border: 1px red solid;
}

.emptyWarning{
    color: red;
}

