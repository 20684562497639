$primary: #ff8c00;
$secondary: #8ccdcc;
$text-primary: #3c211c !important;
$link: #00918e;
$primary-dark: #6d3c00;
$grey: rgb(170, 191, 216);


@mixin btn-text {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

@mixin button {
    @include btn-text;
    padding: 12px;
    border: none !important;
    border-radius: 7px;
}

@mixin secondary-btn {
    @include button;
    color: $primary-dark;
    background-color: rgba(247, 154, 039, 0.25);

    &:hover {
        background-color: lighten(#ff8c00, 30);
        text-decoration: none;
        color: $primary-dark;
    }
}

@mixin primary-btn {
    @include button;
    background-color: $primary;
    color: white !important;
    text-align: center;

    &:hover {
        background-color: lighten(#ff8c00, 10);
        text-decoration: none;
        color: white;
    }
}

.primary-btn {
    @include primary-btn;
    a {
        color: white !important;
    }
}

@mixin link-btn {
    color: $link;
    background-color: white;
    border: none !important;
    padding: 0 !important;
    font-weight: bold;
    height: 48px;

    &:hover {
        text-decoration: none;
        color: darken(#00918e, 10);
    }
}

.quantity-btn {
    @include link-btn;
    font-size: 21px;
    margin-right: $xs;
    margin-left: $xs;

}
