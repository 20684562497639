@mixin small-xs {
  @media (max-width: #{$sm-width}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$sm-width}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$md-width}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$lg-width}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$xl-width}) {
    @content;
  }
}

@mixin md-max {
  @media (max-width: #{$lg-width}) {
    @content;
  }
}

@mixin border {
  border: 1px $grey solid;
}

$border-radius: 7px;

$drop-shadow-sm: 5px 5px 5px rgba(170, 191, 216, 0.35);
$drop-shadow-lg: 15px 15px 15px rgba(170, 191, 216, 0.5);


@mixin badge {
  font-size: 12px;
  color: #00918e;
  background-color: rgba(0, 145, 143, 0.2);
  border-radius: $border-radius;
  height: 18px;
  display: flex;
  vertical-align: center;  
}

.badge {
  @include badge;
}


@mixin btn-group {
  display: flex !important;
  align-items: center;
  justify-content: space-between !important;
  margin-top: $md;
}

