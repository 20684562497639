.products {
    // display: flex !important;
    margin: 0rem;

    @include md {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) );
        margin-right: $lg;
        margin-left: $lg;
        margin-top: $md;
        margin-bottom: $md;

    }

    .product {

        &__card {
            max-width: 300px;
            margin: auto;
            margin-top: 0;
            margin-bottom: 0;
            padding-bottom: 2rem;

        }

        &__image {
            width: 100%;
            padding: $xs;
            // margin-right: $lg;

            // @media screen and (max-width: 766px) {
            //     margin-right: $md;
            // }
        }

        &__imageSmall {
            border: black 4px solid !important;
            border-radius: $border-radius;

        }
    
        &__name {
            color: $text-primary;
            padding-top: 1rem;
            padding-bottom: 0.25rem;
        }
    
        &__details {
            display: flex;
            justify-content: space-between;
            margin-top: 0.75rem;
        }
    
        &__price {
            align-self: center;
            margin: 0;
            color: $text-grey;
        }
    
    
        &__details {
            display: flex;
            justify-content: space-between;
        }
    
        // &__btn {
        //     @include primary-btn;
        // }

    }

}

.thumbnail {
    @include border;
    border-radius: $border-radius;
    margin-right: $sm;
    margin-top: $sm;

}

.thumbnail-container {
    margin-bottom: $lg;
    margin-left: $sm;
}





