@import "/spacing";
@import "/buttons";
@import "/typography";


$primary: #ff8c00;
$secondary: #8ccdcc;
$text-primary: #3c211c !important;
$link: #00918e;
$primary-dark: #6d3c00;
$grey: rgb(170, 191, 216);


// Normal CSS stuff

.product-container-cart {
    border: $grey 1px solid;
    border-radius: 7px;
    padding: $sm;
    margin: $sm;
    box-shadow: 5px 5px 5px rgba(170, 191, 216, 0.35);
    max-width: 700px;
    align-items: center;
}

.quantity-number {
    margin-top: 14px;
}

.section-separator {
    border: $grey 1px solid;
    opacity: 50%;
}

.product-name-price-list {
    padding: $sm;
//     background-color: rgba(170, 191, 216, 0.25);
}

.product-name-price-cart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $sm;
    background-color: rgba(170, 191, 216, 0.25);
    margin-right: $sm;
    margin-left: $sm;
}

.product-name-price-checkout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $sm;
    background-color: rgba(170, 191, 216, 0.25);
}

.price-padding {
    padding-top: 5x;
}


// Product page scss

.product-page {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 75px;
    
}

.image-info {
    display: flex;
}

.product-name {
    @include caption-lg;
    margin-bottom: $sm !important;
    
    @media screen and (max-width: 1168px) {
        margin-top: $sm;
    }

}

.product-info {
    margin-left: $lg;

    @media screen and (max-width: 937px) {
        width: 100%;
        margin-left: $sm;
        margin-right: $sm;
    }
}

.dropdown {
    @include caption-sm;
}

.varient {
    @include caption-sm;
   // opacity: 100%;
}

.ui.selection.dropdown {
    border: 1px $grey solid !important;
    border-radius: 7px !important;
    background-color: none !important;
    margin-bottom: $sm !important;
    margin-top: $xs;
}


.ui.selection {
   color: #3c211c !important;
  // opacity: 100% !important;
   font-size: 16px;
   text-transform: capitalize;
}

// .cart-container {
//     margin: 0 auto;
// }

.cart-child {
    margin: 0 auto;
}

.shopping-cart-text {
    margin-top: $lg !important;
    margin-bottom: $lg
}

.subtotal-and-buttons {
    max-width: 700px;
    margin: 0 auto;
}

.checkout-margin {
    // margin: 0 auto;
    // display: flex;
    // align-items: center;
    // flex-direction: column;
}

.checkout-header {
    display: flex;
    justify-content: center;
    margin-top: $md;
}