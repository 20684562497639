.pagify {
    margin-left: $lg;
    padding-left: $xs;
    padding-bottom: $xs;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    @media screen and (max-width: 766px) {
        width: 100%;
        align-self: center;
        border: none;
        box-shadow: none;
        margin-bottom: 10px;
    }

body {
    background: #fefefe;

    font-family: Sans-Serif;

    line-height: 1.5;
}

form label {
    display: block;
}

header {
    position: fixed;
    padding: 0.5em;
    top: 0;

    text-align: center;

    color: #333;
    border: 0.1em solid #bbb;
}

h1 {
    margin: 0;
}

blockquote p {
    padding: 1em;
    background: rgba(255, 255, 0, 0.13);
}

header .description {
    color: #888;
}

.highlight {
    background: $primary;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 7px;
    margin-right: $xs;
    margin-left: $xs;
}

.normal {
    background: white;
    color: $link;
    font-weight: bold;
    border: none;
    margin-right: $xs;
    margin-left: $xs;
}

.page-btn {
    background-color: rgba(247, 154, 039, 0.25);
    border: none;
    border-radius: 7px;
    color: $primary-dark;
    font-weight: bold;
    margin-right: $xs;
    margin-left: $xs;
}


.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.github-fork-ribbon {
    position: fixed;

    letter-spacing: 0.01em;
}

.go-to-last-page {
    float: right;
}

article {
    display: block !important;

    margin-top: 6em;
    margin-left: auto;
    margin-right: auto;

    max-width: 768px;
}

article section {
    margin-bottom: 2em;
}

article h2 {
    margin-top: 2em;
}

pre {
    background: #fafefe;
    padding: 0.5em;
}

article .description {
    margin: 1em;
    padding: 1em;

    max-width: 60em;

    background: #fafafa;

    border: 0.1em solid #eee;
}

article .description h2 {
    margin: 0;
}
}