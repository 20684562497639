.hero {
    background-image: url("/img/dog2.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 100vh;
  
    img {
      padding: 30px 0 0 30px;
      cursor: pointer;
    }
  
    &__text {
      color: $text-primary;
      position: absolute;
      top: 50%;
      left: 23%;
      transform: translateX(-50%);
      text-align: left;
  
      h1 {
        font-weight: bold;
        font-size: 42px;
      }
    }
  
    .btn {
      background: $color-accent;
      color: white;
      border-radius: 18px;
      text-transform: uppercase;
      &:hover {
        background: darken($color-accent, 8%);
        transition: all 0.3s ease;
      }
      &:active {
        background: darken($color-accent, 25%);
      }
    }
  }