// Font styles
$font-primary: 'Raleway', 'Arial', sans-serif;
$font-secondary: 'Raleway', 'Arial', serif;

// Colors
$bg-color: #E8E2D7;

$text-grey: rgb(67, 67, 67);


$color-accent: #EF4E42;

// Media query sizes
$sm-width: 576px;
$md-width: 768px;
$lg-width: 992px;
$xl-width: 1200px;

.app{
    height: 100%;
    min-height: 100vh;
}