@import "/mixins";

@mixin product-container {
    @include border;
    border-radius: $border-radius;
    box-shadow: $drop-shadow-sm;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  
.product-container {
  @include product-container;
}

.product-container-item-page {
  @include product-container;

  @media screen and (max-width: 766px) {
    width: 100%;
    align-self: center;
    border: none;
    box-shadow: none;
}

}

.product-container-summary {
    @include product-container;
    box-shadow: none;
    margin-right: $md;
    width: 100px;
    height: 100px;
    object-fit: cover;
}
  
.product-container-listing {
  background-color: white;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.item-container {
  @include border;
  margin: $xs;
  box-shadow: $drop-shadow-sm;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  background-color: rgba(170, 191, 216, 0.25);

  @media screen and (max-width: 763px) {
    margin-left: $sm;
    margin-right: $sm;
    margin-top: $sm;
    margin-bottom: $sm;
}

  &:hover {
      box-shadow: $drop-shadow-lg;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
  }

}

.quantity-modal {
    margin-top: $xs;
}

.checkout-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px $grey solid;
    padding-top: $sm;
}

.order-summary {
    @include product-container;
    margin-top: $md;
    height: 100%;

    @media screen and (max-width: 937px) {
      width: 100%;
      margin-left: $sm;
      margin-right: $sm;
      margin-top: 0;
  }
}

.checkout-name-price {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 165px;
}

.checkout-input-wrapper {
  display: flex;
  flex-wrap: wrap;
}



.checkout-form {
  @include product-container;
  width: 50%;
  margin: $md;
  padding: $sm;
  margin-bottom: none;
    @media screen and (max-width: 937px) {
      width: 100%;
      margin-left: $sm;
      margin-right: $sm;
  }
}

.dropdown-mobile-width {
  @media screen and (max-width: 935px) {
    width: 100%;
  } 
}

.dropdown-cart {
  @media screen and (max-width: 935px) {
    width: 100px !important;
  } 
}


.button-mobile-width {
  @media screen and (max-width: 935px) {
    width: 100% !important;
    margin-bottom: $sm;
  } 
}

.added-item {
  margin-left: $md;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &-cart {
    margin-right: $sm;
    margin-left: $sm;
    margin-top: $sm;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &-checkout {
    margin-top: $sm;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width: 935px) {
      width: 100% !important;
      margin-top: $lg;
      justify-content: center;
      flex-direction: column-reverse;
    } 
  }
}

.button-wrapper-checkout {
  margin-right: 10px;
  margin-left: 10px;
}

.active-breadcrumb {
  color: $primary;
  font-weight: bold;
  margin-right: $xs;
}

.inactive-breadcrumb {
  background-color: white;
  border: none;
  color: rgb(163, 162, 162);
}