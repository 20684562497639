a { 
    color: $text-primary;
    &:hover {
        text-decoration: none !important;
    }
}

@mixin title {
    color: $text-primary !important;
    letter-spacing: 0px !important;

    a {
        color: $text-primary !important;
    }

    &:hover {
        text-decoration: none !important;
    }
}

@mixin title-4 {
    @include title;
    font-size: 16px;
    font-weight: bold;
}

.title-4 {
    @include title-4;
}

@mixin title-3 {
    @include title;
    font-size: 21px;
}

.title-3 {
    @include title-3;
}

@mixin title-2 {
    @include title;
    font-size: 32px;
}

.title-2 {
    @include title-2;
}

@mixin body {
    font-size: 16px !important;
    color: $text-primary !important;
    font-weight: normal !important;
}

.body {
    @include body;
}


@mixin caption {
    text-transform: uppercase;
    color: rgb(163, 162, 162);
    font-weight: bold !important;
    letter-spacing: 2px;
}

@mixin caption-sm {
    @include caption;
    font-size: 12px;
}

.caption-sm {
    @include caption-sm;
}

@mixin caption-lg {
    @include caption;
    font-size: 24px;
}

.caption-lg {
    @include caption-lg;
}

.caption-xs {
    font-size: 12px;
    color: grey;
    opacity: 75%;
    letter-spacing: 1px;
    font-weight: normal;

}